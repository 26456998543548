import React from "react"
import styled from "styled-components"

const PriceCardList = () => (
  <ListWrapper role="list">
    {prices.map(price => (
      <PriceCard key={price.price} {...price} />
    ))}
  </ListWrapper>
)

const PriceCard = ({ price, ageRange, pricing, details }) => (
  <CardWrapper>
    <Pricing>{pricing}</Pricing>
    <AgeRange>{ageRange} years</AgeRange>
    <Price>
      <DollarSign>&#36;</DollarSign>
      {price}
    </Price>
    <Details>{details}</Details>
  </CardWrapper>
)

const ListWrapper = styled.ul`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  @media (min-width: 412px) and (max-width: 600px) {
    justify-content: center;
  }
  @media (min-width: 600px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (min-width: 1343px) {
    flex: 1;
  }
`

const CardWrapper = styled.li`
  flex: 1;
  height: 325px;
  padding: 32px;
  background-color: var(--sec-dark);
  color: white;
  border-radius: 3px;

  @media (min-width: 454px) {
    flex: 0 1 268px;
  }
`

const Pricing = styled.h3`
  font-size: ${36 / 16}rem;
  font-weight: 400;
  margin-bottom: 8px;
`

const AgeRange = styled.p`
  margin-bottom: 4px;
  padding-bottom: 8px;
  border-bottom: 1px solid white;
`

const Price = styled.p`
  margin-bottom: 4px;
  font-size: ${28 / 16}rem;
`

const DollarSign = styled.span`
  display: inline-block;
  margin-top: -40%;
  font-size: 1rem;
  transform: translateY(-8px);
`

const Details = styled.p`
  max-width: 300px;
`

const prices = [
  {
    price: 120,
    pricing: "Sprouts",
    ageRange: "0-2",
    details:
      "Includes 3 home visits for the newborns: 1 to 2 days from hospital discharge (at 2 weeks of age and 1 month of age)",
  },
  {
    price: 80,
    pricing: "Seedlings",
    ageRange: "2-5",
    details: "",
  },
  {
    price: 70,
    pricing: "Lil' Beets",
    ageRange: "6-18",
    details: "Includes sports physicals",
  },
]

export default PriceCardList
