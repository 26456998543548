import React from "react"
import styled from "styled-components"

import Link from "./lifestyleLink"

const links = [
  {
    to: "https://nutritionfacts.org/",
    copy: "Nutrition Facts",
  },
  {
    to: "https://nutritionstudies.org/whole-food-plant-based-diet-guide/",
    copy: "Plant based diet guide",
  },
  {
    to: "https://www.primalplay.com/",
    copy: "Primal Play",
  },
  {
    to: "https://www.unwindinganxiety.com/",
    copy: "Unwinding Anxiety",
  },
]
// TODO use a real key when there is real data
const LinkList = () => (
  <StyledList role="list">
    {links.map(link => (
      <Link key={link.to} {...link} />
    ))}
  </StyledList>
)

const StyledList = styled.ul`
  @media (min-width: 320px) {
    display: flex;
    flex-wrap: wrap;
  }
  @media (min-width: 525px) {
    display: grid;
    grid-template-columns: repeat(2, 200px);
    gap: 8px;
  }
`

export default LinkList
