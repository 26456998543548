import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { AnchorLink } from "gatsby-plugin-anchor-links"

const Button = ({ className, children, to, ...props }) => {
  if (to) {
    return (
      <AnchorLink to={to} {...props} className="anchor">
        <StyledButton as="span">{children}</StyledButton>
      </AnchorLink>
    )
  }
  return <StyledButton {...props}>{children}</StyledButton>
}

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  min-height: 50px;
  padding: 0.5rem 1rem;
  border-radius: 40px;
  border: none;
  text-align: center;
  background-color: var(--beet);
  color: white;
  cursor: pointer;
  opacity: 1;
  width: 100%;

  &:hover {
    color: #fff;
    outline: none;
    opacity: 0.85;
  }

  &:focus {
    box-sizing: border-box;
    /* box-shadow: 0 0 2px 2px var(--grapefruit); */
  }

  @media (min-width: 700px) {
    width: 336px;
  }
`

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "pri-light",
    "pri-med",
    "pri-dark",
    "sec-light",
    "sec-dark",
    "carrot",
    "grapefruit",
    "beet",
    "blueberry",
  ]),
}

Button.defaultProps = {
  color: "beet",
}

export default Button
