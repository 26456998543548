import React from "react"
import styled from "styled-components"
import { parseLabel } from "../utils/parse"

const TextField = React.forwardRef(({ id, error, ...props }, ref) => (
  <RootWrapper>
    <Label htmlFor={id}>{parseLabel(id)}</Label>
    <StyledInput
      style={{
        "--box-shadow": error ? "0px 0px 0 1px red" : "none",
        "--border-color": error ? "red" : "var(--pri-med)",
        "--color": error ? "red" : "black",
        "--font-weight": error
          ? "var(--font-weight-bold)"
          : "var(--font-weight-regular)",
      }}
      id={id}
      ref={ref}
      {...props}
    />
  </RootWrapper>
))

const RootWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 0.5rem;
  width: 100%;
  max-width: 273px;
`

const Label = styled.label`
  padding-left: 1rem;
`

const StyledInput = styled.input`
  width: 100%;
  padding: 0.25rem 1rem;
  border: 2px solid;
  border-radius: 20px;
  border-color: var(--color);
  color: var(--color);
  font-weight: var(--font-weight);
  box-shadow: var(--box-shadow);

  &::placeholder {
    font-size: 0.9rem;
  }

  &:focus {
    outline: none;
  }

  &:focus,
  &:active {
    box-shadow: inset 0 0 5px 1px #e577cbd2;
  }
`

export default TextField
