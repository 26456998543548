import React from "react"
import styled from "styled-components"

import Section from "./section"
import Heading from "./typography/heading"

const NonMembers = () => (
  <Section color="sec-dark">
    <Heading tag="h2" style={{ color: "white" }}>
      Non-member services
    </Heading>
    <ServicesList role="list">
      {services.map(({ price, service, note }) => (
        <ListItem key={service}>
          <Price>&#36;{price}</Price>
          <Service>{service}</Service>
          {note && <Note>{note}</Note>}
        </ListItem>
      ))}
    </ServicesList>
  </Section>
)

const ServicesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  color: white;
`

const ListItem = styled.li`
  flex: 1 1 360px;
  margin: 0 1rem;
  padding: 1rem 0 2rem;
  border-top: 1px solid white;

  @media (min-width: 1057px) {
    flex-basis: 160px;

    &:nth-child(4n) {
      flex-basis: 360px;
    }
  }
`

const Price = styled.p`
  font-size: 1.3rem;
  font-weight: 600;
`

const Service = styled.p`
  margin-bottom: 0;
`

const Note = styled.p`
  font-size: ${14 / 16}rem;
`

const services = [
  {
    price: "150",
    service: "Non-member sick visits",
    note: "Per visit",
  },
  {
    price: "165",
    service: "Non-member well check",
    note: "Per visit",
  },
  {
    price: "100",
    service: "Non-member sports physical",
  },
  {
    price: "100",
    service:
      "Lifestyle Medicine consults, for those who want to maintain their pediatrician",
    note: "Per month",
  },
  {
    price: "200",
    service: "Home visits (restrictions apply)",
    note: "Per visit",
  },
  {
    price: "50",
    service: "Telemedicine",
    note: "Per session",
  },
  {
    price: "80",
    service: "Dietician consult",
  },
  {
    price: "100",
    service:
      "Ear piercings; medical grade titanium earrings or plastic earrings for kids with sensitive skin or allergies to jewelry",
  },
]

export default NonMembers
