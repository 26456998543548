import React from "react"
import styled from "styled-components"

import Section from "./section"
import Dual from "./dual"
import LinkList from "./lifestyleAboutLinkList"
import PillarsGrid from "./pillarsGrid"
import Heading from "./typography/heading"

const Lifestyle = () => {
  return (
    <Section id="lifestyle" color="pri-dark">
      <StyledDual>
        <CopyWrapper>
          <Heading tag="h2">The Lifestyle Medicine Approach</Heading>
          <p>
            Lifestyle Medicine is the specialty I came across that ultimately
            pushed me to open my own practice. LM treats each patient from the
            root cause by focusing on its pillars: eating healthy (we’re big
            fans of plant-based meals), having fun with physical activity, the
            importance of sleep, harvesting healthy relationships, stress
            management, and avoiding substances.
          </p>
          <Subheader tag="h3">
            Below are some Lifestyle Medicine resources I highly recommend:
          </Subheader>
          <LinkList />
        </CopyWrapper>
        <PillarsGrid />
      </StyledDual>
    </Section>
  )
}

const StyledDual = styled(Dual)`
  @media (min-width: 1000px) {
    align-items: flex-start;
    max-width: 1300px;
  }
`

const CopyWrapper = styled.div`
  flex: 1;
`

const Subheader = styled(Heading)`
  margin-top: 16px;
`

export default Lifestyle
