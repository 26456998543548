import styled from "styled-components"

const Dual = styled.div`
  position: relative;
  isolation: isolate;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 24px;

  @media (min-width: 1000px) {
    gap: 48px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export default Dual
