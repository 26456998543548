import React from "react"
import styled from "styled-components"

import Section from "./section"
import MaxWidthWrapper from "./maxWidthWrapper"
import Heading from "./typography/heading"

const AboutIntro = () => (
  <RootWrapper>
    <Section color="pri-dark" background="circles">
      <StyledWrapper width={900}>
        <Title tag="h1">
          The Tiny Beet is Savannah’s first Lifestyle Medicine pediatric
          practice.
        </Title>
      </StyledWrapper>
    </Section>
  </RootWrapper>
)

const RootWrapper = styled.div`
  margin-top: 80px;
  height: 80vh;
`

const StyledWrapper = styled(MaxWidthWrapper)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled(Heading)`
  padding-bottom: 10%;
  @media (max-width: 700px) {
    text-align: center;
  }
`

export default AboutIntro
