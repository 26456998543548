import React from "react"
import styled from "styled-components"

import Section from "./section"
import Heading from "./typography/heading"
import PriceCardList from "./priceCard"

const Pricing = () => (
  <Section color="sec-light" id="pricing">
    <Flex style={{ marginBottom: "2rem" }}>
      <Heading tag="h2">
        Our <br />
        memberships <br />
        include:
      </Heading>
      <Flex>
        <ul>
          <li>Dr. Caudill’s direct number to communicate with</li>
          <li>Telemedicine</li>
          <li>Minimal to no wait time in waiting room</li>
          <li>Same or next day sick visits, with appointment</li>
          <li>30 minute blocked visits</li>
          <li>
            All well check up visits as per American Academy of Pediatrics
            guidelines
          </li>
        </ul>
        <ul>
          <li>Discounted price on medications and labs</li>
          <li>Lactation support</li>
          <li>Incision/drainage and simple sutures</li>
          <li>Chronic care management and coordinations</li>
          <li>Lifestyle Medicine education</li>
          <li>Developmental screenings per age group</li>
          <li>Events and workshops at discounted rate</li>
        </ul>
      </Flex>
    </Flex>
    <Flex>
      <PricingHeading>
        <Heading tag="h2">
          Monthly <br />
          Membership <br />
          Tiers:
        </Heading>
        <p>$100 registration fee, per family</p>
        <p>Discounts available for 3 or more children</p>
      </PricingHeading>
      <PriceCardList />
    </Flex>
  </Section>
)

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

const PricingHeading = styled.div`
  flex-basis: 331px;
`

export default Pricing
