import React from "react"
import styled from "styled-components"

const PillarIcon = ({ text, color, image }) => (
  <RootWrapper>
    <ImgWrapper>{image}</ImgWrapper>
    <TextWrapper
      style={{
        "--background-color": color,
      }}
    >
      <Text>{text}</Text>
    </TextWrapper>
  </RootWrapper>
)

const RootWrapper = styled.article`
  flex-basis: 112px;

  @media (min-width: 376px) {
    flex-grow: 0;
    flex-basis: 160px;
  }
  @media (min-width: 604px) {
    flex-basis: 180px;
  }
`
const ImgWrapper = styled.div`
  width: 100%;
`

const TextWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 4px;
  background-color: var(--background-color);

  @media (min-width: 700px) {
    min-width: 130px;
    min-width: 148px;
  }
`

const Text = styled.h4`
  margin: 0;
  text-align: center;
  font-size: 1.1rem;
`

export default PillarIcon
