import React from "react"

import Connection from "./images/pillarConnection"
import Eating from "./images/pillarEating"
import Physical from "./images/pillarPhysical"
import Sleep from "./images/pillarSleep"
import Stress from "./images/pillarStress"

import PillarIcon from "./pillarIcon"
import styled from "styled-components"

const pillars = [
  {
    text: "FOOD",
    color: "#97BF67",
    image: <Eating />,
    alt: "cute cartoon beet, smiley happy face, green background",
  },
  {
    text: "ACTIVITY",
    color: "#F9951D",
    image: <Physical />,
    alt: "cute cartoon beet, sweaty, happy, and wearing a headband, orange background",
  },
  {
    text: "SLEEP",
    color: "#387EC3",
    image: <Sleep />,
    alt: "cute cartoon beet, smiling and resting, blue background",
  },
  {
    text: "STRESS",
    color: "#E578CB",
    image: <Stress />,
    alt: "cute cartoon beet, stressed out and frowning, light fuschia background",
  },
  {
    text: "CONNECTION",
    color: "#CA6D50",
    image: <Connection />,
    alt: "cute cartoon beet, smiling with heart eyes, burnt orange background",
  },
]

const PillarsGrid = () => (
  <RootWrapper>
    {pillars.map(pillar => (
      // <TextSpin key={pillar.text} {...pillar} />
      <PillarIcon key={pillar.text} {...pillar} />
    ))}
  </RootWrapper>
)

const RootWrapper = styled.div`
  flex: 1;
  /*  
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  */

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  /* 
  @media (min-width: 1233px) {
    margin-left: 2rem;
    grid-template-columns: repeat(3, 200px);
    grid-template-rows: repeat(2, 200px);
    row-gap: 2rem;
  }
  */

  @media (max-width: 1000px) {
    max-width: 680px;
  }
`

export default PillarsGrid
