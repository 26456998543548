import React from "react"
import styled from "styled-components"

const LifestyleLink = ({ to, copy }) => (
  <RootWrapper>
    <StyledLink href={to}>{copy}</StyledLink>
  </RootWrapper>
)

const RootWrapper = styled.li`
  flex-basis: 50%;
  margin-top: 8px;
`

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid black;
`

export default LifestyleLink
